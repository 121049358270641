<template>
  <resize-observer @resize="setBoundingRect($event.height)">
    <v-sheet class="pa-4 border-radius">
      <transition-group
        name="slide-x"
        tag="div"
      >
        <document-tab
          v-for="document in displayableDocuments"
          :key="document.type"
          ref="item"
          :active="isActive(document)"
          :document="document"
          :search="search"
          @click="selectDocumentType(document)"
          @select-document="selectDocumentType"
          @hook:mounted="setItemHeight()"
        />
      </transition-group>
      <v-menu
        data-cy="more-documents-button"
        v-if="hasHiddenDocuments"
        v-model="showHiddenDocuments"
        top
        offset-x
        nudge-right=4
        :close-on-content-click="false"
        origin="center center"
        transition="scale-transition"
        eager
      >
        <template v-slot:activator="{ on }">
          <div>
            <document-tab
              :active="Boolean(selectedHiddenDocument.type) && !showHiddenDocuments"
              :document="selectedHiddenDocument"
              :search="search"
              hidden-document
              @select-document="selectDocumentType"
              v-on="on"
            >
              <template v-slot:append-icon>
                <v-icon
                  class="menu-status-icon"
                  :class="{open: showHiddenDocuments}"
                >{{$icon("i.ChevronDown")}}</v-icon>
              </template>
            </document-tab>
          </div>
        </template>
        <search-documents-overflow
          :active-document="search.documentType"
          :documents="hiddenDocuments"
          :search="search"
          @select-document="selectDocumentType"
        />
      </v-menu>
    </v-sheet>
  </resize-observer>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  components: {
    DocumentTab: () => import('./document-tab.vue'),
    SearchDocumentsOverflow: () => import('./search-documents-overflow'),
    ResizeObserver: () => import('@/components/resize-observer')
  },
  data () {
    return {
      boundingRectHeight: 0,
      itemHeight: 68,
      maxDisplayableItems: 1,
      showHiddenDocuments: false
    }
  },
  computed: {
    displayableDocuments () {
      if (!this.hasHiddenDocuments) {
        return this.documentTypes
      }
      return this.documentTypes.slice(0, this.maxDisplayableItems - 1)
    },
    documentTypes () {
      return [...Object.entries(this.search.counts)].map(([type, [count, name]]) => {
        let children = this.search.getDocumentChildren(type)

        children = children.map((c) => {
          return Object.assign({}, c, { parent: { count, name, type } })
        })

        return { count, name, type, children }
      }).filter(_ => !this.search.isChild(_.type))
    },
    hasHiddenDocuments () {
      return this.documentTypes.length > this.maxDisplayableItems
    },
    hiddenDocuments () {
      return this.documentTypes.slice(this.maxDisplayableItems - 1)
    },
    selectedHiddenDocument () {
      return this.hiddenDocuments.filter(d => d.type === this.search.documentType)?.[0] ?? { count: this.hiddenDocuments.length }
    }
  },
  methods: {
    isActive (document) {
      const children = document.children
      const childIdx = children.findIndex((x) => x?.type === this.search.documentType)
      const isChild = childIdx !== -1
      const isActive = document.type === this.search.documentType || isChild

      if (isActive && isChild) {
        const c = Object.assign({}, children[childIdx])
        document.name = c.name
        document.type = c.type
        document.count = c.count
        document.children = [Object.assign({}, c.parent, { parent: { count: c.count, name: c.name, type: c.type } })]
      }
      return isActive
    },
    computeMaxDisplayableItems () {
      this.maxDisplayableItems = Math.max(Math.floor((this.boundingRectHeight - 32) / (this.itemHeight + 8)), 1)
    },
    selectDocumentType (document) {
      if (document.type !== this.search.documentType) {
        this.search.setDocumentType(document.type).execute()
        this.showHiddenDocuments = false
      }
    },
    hasLinkedDocuments (doc) {
      return this.search.getDocumentChildren(doc).length > 0
    },
    setBoundingRect (rect) {
      this.boundingRectHeight = rect
      this.computeMaxDisplayableItems()
    },
    setItemHeight () {
      this.itemHeight = this.$refs?.item?.[0]?.$el.getBoundingClientRect().height ?? 68
      this.computeMaxDisplayableItems()
    }
  },
  watch: {
    hasHiddenDocuments: {
      handler (v) {
        if (!v) {
          this.showHiddenDocuments = false
        }
      }
    }
  },
  props: {
    search: {
      required: true,
      type: Search
    }
  }
}
</script>

<style lang="stylus" scoped>
.menu-status-icon
  transition rotate 0.2s

  &.open
    rotate -90deg
</style>
